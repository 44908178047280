html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.pagination li {
  font-size: 1.25em;
  width: 35px;
  height: 35px;
  text-align: center;
}

.pagination li a {
  display: block;
  color: black;
  text-decoration: none;
}

.pagination li:hover {
  background-color: #ddd;
  cursor: pointer;
}

.pagination .active {
  background-color: #007bff;
}

.pagination .active:hover {
  background-color: #007bff;
}

.pagination .active a {
  color: white;
}

.call-row:hover {
  background-color: #cce5ff;
}
